<div class="sites-infos bg-white">
  <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
    <div class="items-center pb-8 pt-14 sm:pb-14 md:grid md:grid-cols-12 md:gap-16">
      <div class="mb-12 md:mb-0 md:col-span-8">
        <app-sites-switcher
          class="inline-block w-full ml-auto sites-switcher mb-8 md:mb-0 md:w-auto"
          [sites]="sites$ | async"
          [activeSite]="activeSite"
          [version]="versionSize.big"
          [activeReference]="activeReference"
          (switchSiteEmitter)="switchSite($event)"
        >
        </app-sites-switcher>
      </div>
    </div>
  </div>
</div>
<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="accessDynamicTariff">
  <div class="mb-16">
    <div class="md:grid md:grid-cols-12 gap-16">
      <div class="md:col-span-12">
        <div class="content">
          <h3 class="text-4xl font-normal te-text-gradient-home mb-12 mt-20">
            {{ 'pages.dynamicTariff.overview' | translate }}
          </h3>
          <section class="grid md:grid-cols-4 lg:grid-cols-3 gap-10">
            <div class="md:col-span-2 lg:col-span-1">
              <app-exnaton-molecule [exnatonType]="exnatonMoleculeType.comparison"></app-exnaton-molecule>
            </div>
            <div class="md:col-span-2">
              <app-exnaton-molecule [exnatonType]="exnatonMoleculeType.productView"></app-exnaton-molecule>
            </div>
          </section>

          <section class="grid md:grid-cols-4 lg:grid-cols-3 gap-10 mt-10">
            <div class="md:col-span-2 lg:col-span-1">
              <app-exnaton-molecule [exnatonType]="exnatonMoleculeType.currentPrice"></app-exnaton-molecule>
            </div>
            <div class="md:col-span-2">
              <app-exnaton-molecule [exnatonType]="exnatonMoleculeType.priceChart"></app-exnaton-molecule>
            </div>
          </section>

          <section class="grid md:grid-cols-3 gap-10 mt-10">
            <div class="md:col-span-1">
              <app-exnaton-molecule [exnatonType]="exnatonMoleculeType.kpiCard"></app-exnaton-molecule>
            </div>
            <div class="md:col-span-1">
              <app-exnaton-molecule
                [exnatonType]="exnatonMoleculeType.kpiCard"
                [exnatonField]="exnatonMoleculeField.accountTotalPriceForConsumption"
              ></app-exnaton-molecule>
            </div>
            <div class="md:col-span-1">
              <app-exnaton-molecule
                [exnatonType]="exnatonMoleculeType.kpiCard"
                [exnatonField]="exnatonMoleculeField.accountAveragePriceForConsumption"
              ></app-exnaton-molecule>
            </div>
          </section>

          <h3 class="text-4xl font-normal te-text-gradient-home mb-12 mt-20">
            {{ 'pages.dynamicTariff.consumption' | translate }}
          </h3>

          <div class="mb-12">
            <app-exnaton-molecule [exnatonType]="exnatonGroupType.analyseChart"></app-exnaton-molecule>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
