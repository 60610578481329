import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { ExnatonService } from '../exnaton.service';
import { ExnatonGroupType, ExnatonMoleculeField, ExnatonMoleculeType, ExnatonType } from './molecule-type.enum';
import { catchError, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-exnaton-molecule',
  templateUrl: './exnaton-molecule.component.html',
  styleUrls: ['./exnaton-molecule.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ExnatonService],
})
export class ExnatonMoleculeComponent implements OnInit {
  @Input({ required: true }) exnatonType: ExnatonType;
  @Input() exnatonField: ExnatonMoleculeField;

  scriptLoaded: boolean | null = null; // null = init state, true = loaded, false = error

  exnatonMoleculeType = ExnatonMoleculeType;
  exnatonGroupType = ExnatonGroupType;
  exnatonMoleculeField = ExnatonMoleculeField;

  constructor(private exnatonService: ExnatonService) {}

  ngOnInit(): void {
    this.exnatonService.scriptLoaded
      .pipe(catchError(() => of(false)))
      .subscribe((isScriptLoaded: boolean | undefined) => {
        this.scriptLoaded = isScriptLoaded || false;
      });
  }
}
