import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { combineLatest, filter, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { AccessRightsCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { Product } from '@app/modules/customer-zone/user/models/product.interface';

interface ConsumptionView {
  meterReadings: boolean;
  eliq: boolean;
  dynamicTariff: boolean;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './consumption-dashboard.component.html',
  styleUrls: ['./consumption-dashboard.component.scss'],
})
export class ConsumptionDashboardComponent implements OnInit, OnDestroy {
  consumptionView: ConsumptionView = {
    meterReadings: false,
    eliq: false,
    dynamicTariff: false,
  };

  private notifier: Subject<void> = new Subject();

  constructor(public facade: MainFacade) {}

  ngOnInit(): void {
    this.facade.utils.setPageTitle('pages.consumption.pageTitle');

    combineLatest([this.facade.accessRights$, this.facade.activeSite$])
      .pipe(
        tap(() => {
          // reset view displaying flags
          this.consumptionView.eliq = false;
          this.consumptionView.meterReadings = false;
          this.consumptionView.dynamicTariff = false;
        }),
        filter(([accessRights, site]: [AccessRightsCuzoApi, Site]) => {
          if (site) {
            // dynamic tariff dashboard
            site.products.forEach((product: Product) => {
              if (
                this.facade.state$.value.accessRights?.sites?.[site.id]?.eans?.[product.ean]
                  ?.accessDynamicTariffFeatures
              ) {
                this.consumptionView.dynamicTariff = true;
              }
            });
            // meter readings dashboard
            this.consumptionView.meterReadings =
              !this.consumptionView.dynamicTariff && !accessRights?.accessEnergyInsights;

            if (!this.consumptionView.dynamicTariff && !this.consumptionView.meterReadings) {
              // load elig access rights
              return true;
            }
          }

          return false;
        }),
        switchMap(() => {
          return this.facade.eliqAccessRights$;
        }),
        filter((eliqAccessRights: EliqAccessRights) => !!eliqAccessRights),
        takeUntil(this.notifier)
      )
      .subscribe((eliqAccessRights: EliqAccessRights) => {
        // Eliq dashboard
        this.consumptionView.eliq = eliqAccessRights?.accessPerSiteId?.eliqEligible;
      });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}
