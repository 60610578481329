<div [ngSwitch]="exnatonType" *ngIf="scriptLoaded" class="md:h-full border border-solid border-gray-300 bg-white rounded-4xl">
  <div *ngSwitchCase="exnatonMoleculeType.datePicker">
    <ng-container [ngTemplateOutlet]="exnatonDatePicker"></ng-container>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.priceChart">
    <exnaton-price-chart component='{"withDirective": false}'></exnaton-price-chart>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.currentPrice">
    <exnaton-current-price component='{"continuous": false}'></exnaton-current-price>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.detailedReport">
    <exnaton-detailed-report></exnaton-detailed-report>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.analyticsView">
    <exnaton-analytics-view></exnaton-analytics-view>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.comparison">
    <exnaton-comparison></exnaton-comparison>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.productView">
    <exnaton-product-view></exnaton-product-view>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.kpiCard">
    <div [ngSwitch]="exnatonField">
      <div *ngSwitchCase="exnatonMoleculeField.accountTotalPriceForConsumption">
        <exnaton-kpi-card component='{"field": "AccountTotalPriceForConsumption"}'></exnaton-kpi-card>
      </div>
      <div *ngSwitchCase="exnatonMoleculeField.accountAveragePriceForConsumption">
        <exnaton-kpi-card component='{"field": "AccountAveragePriceForConsumption"}'></exnaton-kpi-card>
      </div>
      <div *ngSwitchDefault>
        <exnaton-kpi-card></exnaton-kpi-card>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.financeChart">
    <exnaton-finance-chart></exnaton-finance-chart>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.selfConsumptionChart">
    <exnaton-self-consumption-chart></exnaton-self-consumption-chart>
  </div>
  <div *ngSwitchCase="exnatonGroupType.analyseChart">
    <div class="m-8">
      <ng-container [ngTemplateOutlet]="exnatonDatePicker"></ng-container>
    </div>
    <exnaton-energy-chart></exnaton-energy-chart>
    <exnaton-finance-chart></exnaton-finance-chart>
  </div>
</div>
<div *ngIf="scriptLoaded === false">
  <div class="relative rounded-2xl w-full h-full z-10 flex items-center border-grey">
    <div class="bg-white p-8 text-primary rounded-2xl">
      <img src="./assets/img/error-red.svg" class="inline-block mr-4 w-6" />
      <span>{{ 'components.dynamicTariff.error' | translate }}</span>
    </div>
  </div>
</div>

<ng-template #exnatonDatePicker>
  <exnaton-date-picker
    component='{"withHeader": true}'
    theme='{"primary":"#2450FF","defaultRadius":"$sm"}'
  ></exnaton-date-picker>
</ng-template>
