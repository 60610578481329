<div *ngIf="accessDynamicTariff" class="pb-10">
  <h2 class="text-2xl font-sans text-grey-dark60 font-medium mb-8">
    {{ 'pages.dashboard.dynamicTariff.title' | translate }}
  </h2>
  <section id="dynamic-tariff" class="grid md:grid-cols-4 lg:grid-cols-3 gap-10">
    <div class="md:col-span-2 lg:col-span-1">
      <app-exnaton-molecule [exnatonType]="exnatonMoleculeType.currentPrice"></app-exnaton-molecule>
    </div>
    <div class="md:col-span-2">
      <app-exnaton-molecule [exnatonType]="exnatonMoleculeType.priceChart"></app-exnaton-molecule>
    </div>
  </section>
</div>
