import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { ExnatonMoleculeComponent } from '@app/modules/customer-zone/exnaton/exnaton-molecule/exnaton-molecule.component';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { VersionSize } from '@app/shared/models/units.interface';
import { TranslateModule } from '@ngx-translate/core';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { SitesSwitcherComponent } from '@app/shared/components/sites-switcher/sites-switcher.component';
import { Product } from '@app/modules/customer-zone/user/models/product.interface';
import {
  ExnatonMoleculeType,
  ExnatonGroupType,
  ExnatonMoleculeField,
} from '@app/modules/customer-zone/exnaton/exnaton-molecule/molecule-type.enum';

@Component({
  selector: 'app-dynamic-tariff',
  templateUrl: './dynamic-tariff.component.html',
  styleUrls: ['./dynamic-tariff.component.scss'],
  standalone: true,
  imports: [NgIf, AsyncPipe, TranslateModule, SitesSwitcherComponent, ExnatonMoleculeComponent],
})
export class DynamicTariffComponent implements OnInit {
  sites$: Observable<Site[]>;
  activeSite: Site;
  activeReference: string;
  activeSiteId: string;
  notifier = new Subject<void>();
  accessDynamicTariff = false;

  versionSize = VersionSize;
  exnatonMoleculeType = ExnatonMoleculeType;
  exnatonGroupType = ExnatonGroupType;
  exnatonMoleculeField = ExnatonMoleculeField;

  constructor(private facade: MainFacade) {}

  ngOnInit(): void {
    this.facade.utils.setPageTitle('pages.meterReading.pageTitle');
    this.facade.activeSite$
      .pipe(
        filter((site: Site) => !!site),
        takeUntil(this.notifier)
      )
      .subscribe((site: Site) => {
        this.activeReference = this.facade.state$.value.reference;
        this.sites$ = this.facade.sites$;
        this.activeSiteId = site?.id;
        this.activeSite = site;

        this.accessDynamicTariff = false;
        site.products.forEach((product: Product) => {
          if (
            this.facade.state$.value.accessRights?.sites?.[site.id]?.eans?.[product.ean]?.accessDynamicTariffFeatures
          ) {
            this.accessDynamicTariff = true;
          }
        });
      });
  }

  public switchSite(siteId: string) {
    this.facade.updateActiveSite(siteId);
  }
}
