<ng-container *ngIf="consumptionView.meterReadings">
  <app-meter-reading></app-meter-reading>
</ng-container>

<ng-container *ngIf="consumptionView.eliq">
  <app-eliq-dashboard></app-eliq-dashboard>
</ng-container>

<ng-container *ngIf="consumptionView.dynamicTariff">
  <app-dynamic-tariff></app-dynamic-tariff>
</ng-container>
