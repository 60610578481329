<div class="flex-1">
  <div class="flex flex-col h-full bg-white rounded-4xl box-shadow-small">
    <header class="flex items-center py-6 px-8 rounded-t-4xl font-medium bg-turquoise">
      <span class="rounded-full">
        <app-image-icon
          altDescription="{{ 'components.contracts.products.' + contract.type | translate | titlecase }}"
          imagePath="./assets/img/icons/standalone/red/{{ contract.type | lowercase }}.svg"
        >
        </app-image-icon>
      </span>
      <h5 class="text-2xl pl-8 font-medium text-grey-dark">
        <ng-container *ngIf="contract.i18n">
          {{ contract.i18n[translate.currentLang] }}
        </ng-container>
        <ng-container *ngIf="!contract.i18n">
          <ng-container *ngIf="contract.type !== 'ASSISTANCE'">
            {{ 'components.contracts.products.' + contract.type | translate }}
          </ng-container>
          <ng-container *ngIf="contract.type === 'ASSISTANCE'">
            {{ 'components.contracts.products.' + contract.name | translate }}
          </ng-container>
        </ng-container>
      </h5>
    </header>

    <div class="justify-self-start flex-1 p-8">
      <div *ngIf="isProductGasOrElec()">
        <div class="flex items-center mt-4 mb-8">
          <span class="font-medium">{{ 'components.contracts.contract.type' | translate }}</span>
          <img
            *ngIf="contract.tariff.type !== 'SOCIAL' && contract.bundleDetails?.picture !== null"
            class="inline-block ml-6 align-middle max-h-14"
            height="35"
            [src]="contract.bundleDetails?.picture"
            alt="{{ contract.name | lowercase }}"
          />
          <div
            *ngIf="contract.tariff.type === 'SOCIAL' || contract.bundleDetails?.picture === null"
            class="font-medium text-3xl te-text-gradient-home inline-block ml-6 max-h-14"
          >
            {{
              contract.tariff.type === 'SOCIAL'
                ? ('components.contracts.modal.socialTariff' | translate)
                : contract.name
            }}
          </div>
        </div>

        <div class="mt-8 mb-8">
          <span class="font-medium">{{ 'components.contracts.contract.tariff' | translate }}</span>
          <small class="font-normal text-sm ml-8">{{
            'components.contracts.contract.tariff_type.' + contract.tariff.type | lowercase | translate
          }}</small>
        </div>
        <!-- TODO: Promotion is missing on API, but it is regression and it should be added soon -->
        <!-- <div
        *ngIf="contract.promotion && contract.promotion.name"
        class="flex justify-center rounded-xl mb-2 mt-2 p-4 bg-yellow"
      >
        <span class="font-medium mr-4">{{ 'components.contracts.contract.promotion' | translate }}</span>
        <span class="font-normal">{{ contract.promotion.name }}</span>
      </div> -->
      </div>

      <div *ngIf="!isProductGasOrElec()">
        <div>
          <span *ngIf="contract.type !== 'ASSISTANCE'">
            {{ 'components.contracts.products_description.' + contract.type | translate }}
          </span>
          <span *ngIf="contract.type === 'ASSISTANCE'">
            {{ 'components.contracts.products_description.' + contract.name | translate }}
          </span>
        </div>

        <div *ngIf="contract.subscription" class="text-center mt-16">
          <span class="inline-block align-bottom">{{ 'components.contracts.contract.price' | translate }}</span>
          <span class="inline-block ml-8 mr-4">{{ contract.subscription | number }}</span>
          <small class="inline-block align-bottom">{{
            'components.contracts.contract.currencyMonth' | translate
          }}</small>
        </div>
      </div>
    </div>
    <footer class="rounded-b-4xl font-bold text-center p-4 pb-6 pt-0">
      <a
        class="font-bold text-sm text-primary cursor-pointer"
        (click)="openContractDetail()"
        [analyticsEvent]="'contract:click:open-contract-details'"
      >
        {{ 'components.contracts.contract.links.showDetails' | translate }}
      </a>
    </footer>
  </div>

  <ng-container *accessControl="accessRight.viewTariffCard">
    <div *ngIf="contract?.tariffCards && contract?.tariffCards?.length" class="flex flex-col md:mt-4 md:mb-8">
      <div
        *ngFor="let tariffCard of contract?.tariffCards"
        class="flex justify-between items-center mx-8 mt-4 py-4 px-8 flex rounded-2xl bg-white"
      >
        <a
          class="flex font-bold text-sm text-primary cursor-pointer"
          href="{{ tariffCard.url }}"
          target="_blank"
          [analyticsEvent]="'contract:click:open-pricing-grid'"
        >
          <img src="./assets/img/icons/standalone/red/download_pdf.svg" class="mr-4 w-8" />
          {{ 'components.contracts.contract.links.downloadPricingGrid.' + tariffCard.type | translate }}
        </a>
        <app-tooltip
          class="tooltip-xs"
          [tooltip]="'components.contracts.contract.links.downloadPricingGrid.tooltip'"
          [position]="'top'"
          [contentClass]="'!w-[50rem]'"
        ></app-tooltip>
      </div>
    </div>
  </ng-container>
</div>
