import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { ExnatonMoleculeComponent } from '@app/modules/customer-zone/exnaton/exnaton-molecule/exnaton-molecule.component';
import { NgIf } from '@angular/common';
import { filter, Subject } from 'rxjs';
import { MainFacade } from '@app/core/facade/main.facade';
import { ExnatonMoleculeType } from '@app/modules/customer-zone/exnaton/exnaton-molecule/molecule-type.enum';

@Component({
  selector: 'app-dynamic-tariff-preview',
  templateUrl: './dynamic-tariff-preview.component.html',
  styleUrls: ['./dynamic-tariff-preview.component.scss'],
  standalone: true,
  imports: [TranslateModule, ExnatonMoleculeComponent, NgIf],
})
export class DynamicTariffPreviewComponent implements OnInit {
  accessDynamicTariff: boolean = false;
  notifier = new Subject<void>();

  exnatonMoleculeType = ExnatonMoleculeType;

  constructor(private facade: MainFacade) {}

  ngOnInit(): void {
    this.facade.activeSite$.pipe(filter((site: Site) => !!site)).subscribe((site: Site) => {
      this.accessDynamicTariff = false;
      const accessRights = this.facade.state$.value.accessRights;

      site.products.forEach((product) => {
        if (accessRights?.sites?.[site.id]?.eans?.[product.ean]?.accessDynamicTariffFeatures) {
          this.accessDynamicTariff = true;
        }
      });
    });
  }
}
